import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { CountryDesc } from './country-desc';
import { ProductForm } from './product-forms';
import { getMinDate, getMinDateTT, countryVehicleTypes, isFunction } from './form-singles';
import { getProductCode } from './pricing';

export const countries = [
	["gv", "Vouchery – dárkové poukazy"],
	["cz", "Česká republika"],
	["ost", "Rakousko"],
	["ch", "Švýcarsko"],
	["sk", "Slovensko"],
	["hu", "Maďarsko"],
	//["slo", "Slovinsko"],
	["slon", "Slovinsko"],
	["hr", "Chorvatsko"],
	["it", "Itálie"],
	["fr", "Francie"],
	["no-dk-swe", "Skandinávie – Norsko, Švédsko, Dánsko"],
];

export const countryTollTypes = {
	"cz": [
		["etoll", "E-Známka"],
	     // ["voucher", "Nákup voucheru"],
		["mass", "Hromadný nákup"],
		["vhelp", "Uplatnění voucheru"]
	],
	"ost": [
		["etoll", "E-Známka"],
		["tunnel", "Tunel"],
		["vignette", "Kupon"]
	],
	'ch': [
		["etoll", "E-Známka"],
		["vignette", "Kupon"]
	]
};

export function countryIsUnit(cc) {
	switch (cc) {
		case 'hr':
		case 'it':
		case 'fr':
		case 'no-dk-swe':
			return true;
	}
	return false;
}

export function CountryCard({ initCountry, holidays, removeItem, itemId, data, updateItems, lists, getValidities }) {

	//console.log(data);
	function wrap_setter(orig_setter, key) {
		return function (v) {
			// console.log('Storing ' + key + ' in data: ' + v);
			data[key] = v;
			orig_setter(v);
			updateItems();
		};
	}

	//console.log("*************************************");
	const [country, X_setCountry] = useState(initCountry);
	const setCountry = wrap_setter(X_setCountry, 'country'); // I sel-country 1/2

    const [tollType, X_setTollType] = useState(initCountry === "gv" ? null : initCountry === "cz" ? "etoll" : "");
	const setTollType = wrap_setter(X_setTollType, 'tollType'); // I sel-country 2/2

    const [validity, X_setValidity] =
	  useState(initCountry === "gv" ?
		   getValidities("gv", 0)[0][0] :
		   initCountry === "cz" ?
		   getValidities("cze", 0)[0][0] : null);
    const setValidityX = wrap_setter(X_setValidity, 'validity'); // I sel-validity & sel-ways ?!?!

    const [vehicle, X_setVehicle] = useState(initCountry === "gv" ? 0 : null);
	const setVehicle = wrap_setter(X_setVehicle, 'vehicle'); // I sel-typeset

	const [regCountry, X_setRegCountry] = useState("CZ");
	const setRegCountry = wrap_setter(X_setRegCountry, 'regCountry'); // I sel-regcountry

	const [returnMethod, X_setReturnMethod] = useState(null);
	const setReturnMethod = wrap_setter(X_setReturnMethod, 'returnMethod'); // I sel-return

	const [tunnel, X_setTunnel] = useState(null); // pro úsek z tunelů, takhle zvlášť? jako má to vlastní form control
	const setTunnel = wrap_setter(X_setTunnel, 'tunnel'); // I sel-tunnel

	const [SPZ, X_setSPZ] = useState("");
	const setSPZ = wrap_setter(X_setSPZ, 'spz'); // I in-spz

	const [credit, X_setCredit] = useState(null);
	const setCredit = wrap_setter(X_setCredit, 'credit'); // I in-credit

	const [count, X_setCount] = useState(1);
	const setCount = wrap_setter(X_setCount, 'count'); // I in-count

	// toto je jen lokální stav uvnitř date-picker custom inputu, tady být nemusí => pak smazat a uklidit
	const [startDate, X_setStartDate] = useState(new Date());
	const setStartDate = wrap_setter(X_setStartDate, 'startDate'); // Ano, asi zrušit ;-) --joe

	var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
	var tomorrow2 = new Date();
    tomorrow2.setDate(tomorrow.getDate() + 1);
    if (validity == 17) {
	tomorrow2.setDate(tomorrow.getDate()+1);
    }
	const [validFromDate, X_setValidFromDate] = useState(tomorrow2);
    const setValidFromDate = wrap_setter(X_setValidFromDate, 'validFromDate'); // date-validfrom
    function setValidity(v) {
	setValidityX(v);
	const minValidFromDate = v == 17 ? tomorrow2 : tomorrow;
	if (validFromDate < minValidFromDate) {
	    setValidFromDate(minValidFromDate);
	}
    }

	const [deliveryDate, X_setDeliveryDate] = useState(new Date());
	const setDeliveryDate = wrap_setter(X_setDeliveryDate, 'deliveryDate'); // date-datefrom

	const [returnDate, X_setReturnDate] = useState(tomorrow);
	const setReturnDate = wrap_setter(X_setReturnDate, 'returnDate'); // date-dateto
	//console.log('cc tomorrow = ' + tomorrow);
	//console.log('cc returndate = ' + returnDate);

	const [SPZcrowd, X_setSPZcrowd] = useState("");
	const setSPZcrowd = wrap_setter(X_setSPZcrowd, 'spzs'); // I in-spzs

    const [productCode, X_setProductCode] = useState(initCountry === "gv" ? "gv" : "");
	const setProductCode = wrap_setter(X_setProductCode, 'productCode');

	const [inscription, X_setInscription] = useState("");
	const setInscription = wrap_setter(X_setInscription, 'inscription');


	//const setProductCode = getProductCode(country, tollType);

	//console.log("ProductCode: " + productCode);


	/*
	  console.log("Received tolltype in CC: " + tollType);
	  console.log("Received validity in CC: " + validity);
	  console.log("Received vehicle in CC: " + vehicle);
	  console.log("Received regCountry in CC: " + regCountry);
	  console.log("Received returnMethod in CC: " + returnMethod);
	  console.log("Received tunnel in CC: " + tunnel);
	  console.log("Received SPZ in CC: " + SPZ);
	  console.log("Received Credit in CC: " + credit);
	  console.log("Received Count in CC: " + count);
	  // console.log("Received startDate in CC: " + startDate);
	  console.log("Received valid from date in CC: " + validFromDate);
	  console.log("Received deliveryDate in CC: " + deliveryDate);
	  console.log("Received returnDate in CC: " + returnDate);
	  console.log("Received SPZ crowd in CC: " + SPZcrowd);
	  /**/

	// console.log("Product code: " + getProductCode(country, tollType));

	function removeMe() {
		//console.log('Clicked removeMe, itemId: ' + itemId);
		removeItem(itemId);
	}

	/**************************************************/

	const changeTollType = function (tt) {
		// console.log('**************** changeTollType ' + tt);
		setTollType(tt);
		const newProductCode = getProductCode(country, tt);
		setProductCode(newProductCode);
		const newTunnel = lists.tunnels[0].id;
		const validities = getValidities(newProductCode, vehicle, newTunnel);
		setTunnel(newTunnel);
		if ((validities) && (validities.length > 0)) {
			const remainValidities = validities.filter((val) => val[0] == validity);
			// console.log('validity before change: ' + validity);
			// console.log('remainValidities = ' + remainValidities);
			const validValidity = remainValidities.length > 0;
			// console.log("**** keep validity? " + validValidity);
			if (!validValidity) {
				// console.log('NOT');
				setValidity(validities[0][0]);
			} else {
				setValidity(validity);
			}
		} else {
			setValidity(null);
		}
	};

	// console.log("tollType in cc " + tollType);
	// console.log("validity in cc " + validity);
	// nezbyde mi než strčit tolltype nahoru a předívat ho obousměrně jako tolltype a setTollType
	const changeCountry = function (ev) {    // jenže, to stejný musím dělat i někdy po ChangeTollType
		//const productCode = getProductCode(country, tolltype);
		const CC = ev.target.value;
		setCountry(CC);
		setValidFromDate(tomorrow);
		const minDate = (CC === "it") || (CC === "no-dk-swe") || (CC === "fr") ?
			getMinDateTT() : getMinDate();
		setDeliveryDate(minDate);
		setReturnDate(tomorrow);
		setCount(1);
		const types = countryTollTypes[CC];
		var newTollType = null;
		if (types) {
			setTollType(types[0][0]);
			newTollType = types[0][0];
			setProductCode(getProductCode(CC, types[0][0]));
		} else {
			setTollType(null);
			setProductCode(getProductCode(CC, null));
		}
		const newProductCode = getProductCode(CC, newTollType);
		setProductCode(newProductCode);
		const vehicleTypes = lists.typeSets[CC];
		//const vehicleTypes = countryVehicleTypes[CC];
		var newVehicleType = null;
		//console.log(country);
		//console.log(CC);
		if (CC === 'gv') {   //This is piggy setting of vehicle for country 'gv' because vehicle type for GV is missing in typeSets right now :)) - consult! and probably change
			setVehicle(0);
		}
		else if (vehicleTypes && CC !== "slon") {
			setVehicle(vehicleTypes[0][0]);
			newVehicleType = vehicleTypes[0][0];
		} else if ((vehicleTypes || country !== CC) && CC === "slon") {
			//console.log("inside");
			const vehicleTypes2 = rightSorting(vehicleTypes, true, 'slon', "");
			setVehicle(vehicleTypes2[0][0]);
			newVehicleType = vehicleTypes2[0][0];
		}
		else {
			setVehicle(null);
		}
		const validities = getValidities(newProductCode, newVehicleType);
		if ((validities) && (validities.length > 0)) {
			const validValidity = validities.filter((val) => val[0] == validity).length > 0;
			// console.log("keeping validity");
			if (!validValidity) {
				setValidity(validities[0][0]);
			}
		} else {
			setValidity(null);
		}
		setRegCountry('CZ');
		if (CC === 'hr' || CC === 'it' || CC === 'fr' || CC === 'no-dk-swe') {
			//console.log(CC);
			setReturnMethod(2);
			if (CC === 'hr') {
				setCredit(2500);
			} else {
				setCredit(3000);
			}
		} else {
			setReturnMethod(null);
			setCredit(null);
		}

	};

	const changeVehicle = function (newVehicleType) {
		setVehicle(newVehicleType);
		const validities = getValidities(productCode, newVehicleType, tunnel);
		if ((validities) && (validities.length > 0)) {
			const validValidity = validities.filter((val) => val[0] == validity).length > 0;
			if (!validValidity) {
				setValidity(validities[0][0]);
			}
		} else {
			setValidity(null);
		}
	}

	const changeTunnel = function (newTunnel) {
		const validities = getValidities(productCode, vehicle, newTunnel);
		setTunnel(newTunnel);
		if ((validities) && (validities.length > 0)) {
			const validValidity = validities.filter((val) => val[0] == validity).length > 0;
			if (!validValidity) {
				setValidity(validities[0][0]);
			}
		} else {
			setValidity(null);
		}
	}

	var countryForm = <></>;
	switch (country) {
		case 'cz':
		case 'ost':
		case 'ch':
			countryForm = <TollTypeForm
				lists={lists}
				getValidities={getValidities}
				tunnel={tunnel}
				removeMe={removeMe}
				onChange={changeTollType}
				tollType={tollType}
				holidays={holidays}
				country={country}
				setValidity={setValidity}
				validity={validity}
				setRegCountry={setRegCountry}
				setVehicle={changeVehicle}
				vehicle={vehicle}
				setCount={setCount}
				count={count}
				setSPZ={setSPZ}
				setTunnel={changeTunnel}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate}
					  setSPZcrowd={setSPZcrowd}
				onlyUntil={country === 'cz' ? 30 : false}
				      />;
			break;
		case 'sk':
		case 'hu':
		case 'slon':
			countryForm = <CountryHead
				lists={lists}
				getValidities={getValidities}
				tunnel={tunnel}
				removeMe={removeMe}
				holidays={holidays}
				country={country}
				tolltype="etoll"
				setValidity={setValidity}
				validity={validity}
				count={count}
				onlyUntil={country === 'sk' && validity == 8 ? 14 : false}
				setRegCountry={setRegCountry}
				setVehicle={changeVehicle}
				vehicle={vehicle}
				setSPZ={setSPZ}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate} />;
			break;
		//case 'ch':
		case 'slo':
			countryForm = <CountryHead
				lists={lists}
				getValidities={getValidities}
				tunnel={tunnel}
				removeMe={removeMe}
				holidays={holidays}
				country={country}
				tolltype="vignette"
				setValidity={setValidity}
				validity={validity}
				setCount={setCount}
				count={count}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate} />;
			break;
		case 'hr':
		case 'it':
		case 'fr':
		case 'no-dk-swe':
			countryForm = <CountryHead
				lists={lists}
				getValidities={getValidities}
				tunnel={tunnel}
				removeMe={removeMe}
				holidays={holidays}
				country={country}
				tolltype="unit"
				setValidity={setValidity}
				setRegCountry={setRegCountry}
				setVehicle={changeVehicle}
				vehicle={vehicle}
				setReturnMethod={setReturnMethod}
				setSPZ={setSPZ}
				setCredit={setCredit}
				credit={credit}
				setStartDate={setStartDate}
				setCount={setCount}
				count={count}
				setDeliveryDate={setDeliveryDate}
				deliveryDate={deliveryDate}
				setReturnDate={setReturnDate}
				returnDate={returnDate}
			/>;
			break;
		case 'gv':
			countryForm = <CountryHead
				lists={lists}
				getValidities={getValidities}
				tunnel={tunnel}
				removeMe={removeMe}
				holidays={holidays}
				country={country}
				tolltype="voucher"
				setValidity={setValidity}
				validity={validity}
				count={count}
				setCount={setCount}
				onlyUntil={country === 'sk' && validity == 8 ? 14 : false}
				setRegCountry={setRegCountry}
				setVehicle={changeVehicle}
				vehicle={vehicle}
				setSPZ={setSPZ}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate}
				inscription={inscription}
				setInscription={setInscription} />;
			break;
	};

	return (
		<Card body className="mb-2">
			<Form.Group as={Row} controlId="toll-country">
				<Form.Label column sm="5" md="4"  >
					Do jaké země cestujete?
					<br />
					Nebo chcete koupit / uplatnit voucher?
				</Form.Label>
				<Col sm="7" md="8">
					<Form.Control size="sm" as="select" className='mt-3' value={country} onChange={changeCountry}>
						<option value="--">* Vyberte ze seznamu*</option>
						<RenderCountryOptions options={countries} />
					</Form.Control>
				</Col>
			</Form.Group>
			{countryForm}
		</Card>
	);
}

// function that sorts options alphabetically for hungary and numerically for slovenia
export function rightSorting(options, sorting, country, vehicle) {
	//console.log(options);
	if (sorting && country === "slon" && vehicle === "") {
		return options.sort();
	}
	const hungary = sorting && country === "hu";
	const slovenia_motorbike = sorting && vehicle === "14" && country === "slon"
	if (hungary || slovenia_motorbike) {
		return options.sort(function (a, b) {
			if (a[1] < b[1]) {
				return -1;
			}
			if (a[1] > b[1]) {
				return 1;
			}
			return 0;
		});
	} else {
		return options;
	}
}

export function RenderCountryOptions({ options, sorting = false, country = "", vehicle = "" }) {
	//console.log(vehicle);
	//console.log(country);
	//console.log(sorting);

	const sorted = rightSorting(options, sorting, country, vehicle);
	return (
		<>
			{
				sorted.map(function (cntr) {
					return (
						<option value={cntr[0]} key={cntr[0]}>
							{cntr[1]}
						</option>
					);
				})
			}
		</>
	);
}

function TollTypeForm({ onChange, country, tollType, setValidity, validity, setRegCountry, setVehicle, vehicle, setReturnMethod, setSPZ, setTunnel, setCredit, credit, setCount, count, setStartDate, setValidFromDate, setDeliveryDate, deliveryDate, setReturnDate, returnDate, setSPZcrowd, holidays, removeMe, lists, tunnel, getValidities, validFromDate, onlyUntil }) {

	const types = countryTollTypes[country];

	const changeType = function (ev) {
		if (isFunction(onChange)) {
			onChange(ev.target.value);
		}
		setCount(1);
		/* // Must be changed directly in country-card
		const validities = getValidities(getProductCode(country, tollType), vehicle, tunnel);
		if (validities) {
			setValidity(validities[0][0]);
		} else {
			setValidity(null);
		}
		*/
	};

	return (
		<>
			<Form.Group as={Row} controlId="toll-type">
				<Form.Label column sm="5" md="4">Vyberte typ mýtného</Form.Label>
				<Col sm="7" md="8">
					<Form.Control size="sm" defaultValue={types[0][0]} as="select" onChange={changeType}>
						<RenderCountryOptions options={types} />
					</Form.Control>
				</Col>
			</Form.Group>
			{tollType == 'vhelp' ?
				<div className="text-center">
					<Button href="/voucher/" size='sm' variant="success" >Uplatnit voucher na e-známku</Button>
				</div>
				:
				<CountryHead
					lists={lists}
					tunnel={tunnel}
					getValidities={getValidities}
					removeMe={removeMe}
					holidays={holidays}
					country={country}
					tolltype={tollType}
					setValidity={setValidity}
					validity={validity}
					setRegCountry={setRegCountry}
					setVehicle={setVehicle}
					vehicle={vehicle}
					setReturnMethod={setReturnMethod}
					setSPZ={setSPZ}
					setTunnel={setTunnel}
					setCredit={setCredit}
					credit={credit}
					setCount={setCount}
					count={count}
					setStartDate={setStartDate}
					setValidFromDate={setValidFromDate}
					setDeliveryDate={setDeliveryDate}
					deliveryDate={deliveryDate}
					setReturnDate={setReturnDate}
					setSPZcrowd={setSPZcrowd}
					returnDate={returnDate}
				    validFromDate={validFromDate}
				    onlyUntil={onlyUntil}
				/>}
		</>
	);
}


function CountryHead({ country, tolltype, setValidity, validity, setRegCountry, setVehicle, vehicle, setReturnMethod, setSPZ, setTunnel, setCredit, credit,
	setCount, count, setStartDate, setValidFromDate, setDeliveryDate, deliveryDate, setReturnDate, returnDate, setSPZcrowd, holidays, removeMe, lists, onlyUntil,
	tunnel, getValidities, validFromDate, inscription, setInscription }) {
	//console.log('ch validFrom = ' + validFromDate);

	return (
		<>
			<CountryDesc country={country} tolltype={tolltype} />
			<ProductForm country={country}
				lists={lists}
				onlyUntil={onlyUntil}
				tunnel={tunnel}
				getValidities={getValidities}
				holidays={holidays}
				tolltype={tolltype}
				setValidity={setValidity}
				validity={validity}
				setRegCountry={setRegCountry}
				setVehicle={setVehicle}
				vehicle={vehicle}
				setReturnMethod={setReturnMethod}
				setSPZ={setSPZ}
				setTunnel={setTunnel}
				setCredit={setCredit}
				credit={credit}
				setCount={setCount}
				count={count}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate}
				setDeliveryDate={setDeliveryDate}
				setReturnDate={setReturnDate}
				setSPZcrowd={setSPZcrowd}
				deliveryDate={deliveryDate}
				returnDate={returnDate}
				validFromDate={validFromDate}
				inscription={inscription}
				setInscription={setInscription} />
			<br />
			<div className="text-center ">
				<Button size="sm" variant="danger" className="float-right" onClick={removeMe}>Odebrat položku</Button>
			</div>
		</>
	);
}

export function PayOrContinue({ addItem }) {
	return (
		<div className="text-center">
			{/* <Button size="sm" className="m-2" variant="primary">Doprava a platba</Button> */}
			<Button size="sm" variant="primary" className="m-2" onClick={addItem}>Přidat další položku</Button>
		</div>
	);
}
