import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

export function CountryDesc({ country, tolltype }) {
    switch (country) {
        case 'cz':
            switch (tolltype) {
                case 'etoll':
                    return (
                        <>
                            <h4 className="mb-2 mt-4">Česká e-známka</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="cze.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>pro vozidla do 3,5t</li>
                                        <li className="text-danger">objednávky uhrazené po 15 hod. budou vyřízeny nejpozději následující den</li>
                                        <li className="text-danger">POZOR jednodenní e-známka neplatí 24 hod. od registrace, ale od 0:00 do 23:59 hod. požadovaného dne</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Dárkový poukaz - voucher</li>
                                                <li>Slovenská e-známka</li>
                                                <li>Rakouský kupón - papírový</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                case 'voucher':
                    return (
                        <>
                            <h4>Česká e-známka - voucher</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="czv.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>praktický a užitečný dárek pro firemní klienty i jednotlivce</li>
                                        <li>získáte dárkový poukaz, který platí po dobu 12 měsíců</li>
                                        <li>možnost vložit logo firmy nebo osobní věnování</li>
                                        <li>pro vozidla do 3,5t</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Rakouský kupón - papírový</li>
                                                <li>Česká e-známka</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                case 'mass':
                    return (
                        <>
                            <h4>Česká e-známka - hromadný nákup více kusů</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="czh.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>pro vlastníky flotil a vozových parků</li>
                                        <li>jednoduše zkopírujte SPZ z Vašeho textového nebo tabulkového editoru</li>
                                        <li>pro vozidla do 3,5t</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Slovenská e-známka</li>
                                                <li>Rakouský kupón - papírový</li>
                                                <li>Maďarská e-známka</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                default:
                    return (
                        <p>Neznámé české mýto.</p>
                    );
            }

        case 'ost':
            switch (tolltype) {
                case 'etoll':
                    return (
                        <>
                            <h4>Rakouská e-známka</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="oste.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>cestujte bez starostí, vyřešte dálniční poplatky v předstihu</li>
                                        <li>e-známky mají v Rakousku stejnou platnost jako papírové kupony</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Slovenská e-známka</li>
                                                <li>Chorvatská mýtná jednotka</li>
                                                <li>Rakouské úsekové mýto</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                case 'tunnel':
                    return (
                        <>
                            <h4>Rakouské úsekové mýto – tunely a placené úseky</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="ostet.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>ušetřete starosti a mějte vyřešený průjezd tunelem již před cestou</li>
                                        <li> nečekejte na platidle, projíždějte volným jízdním pruhem</li>
                                        <li>nabízíme mýto pro všechny rakouské placené tunely a úseky</li>
                                        <li>pro vozidla do 3,5t</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Rakouská e-známka</li>
                                                <li>Slovinský kupón – papírový</li>
                                                <li>Chorvatská mýtná jednotka</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                case 'vignette':
                    return (
                        <>
                            <h4>Rakouský kupón - papírový</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="oste.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>cestujte bez starostí, vyřešte dálniční poplatky v předstihu</li>
                                        <li>k nalepení na čelní sklo vozidla</li>
                                        <li>papírové kupony mají stejnou platnost jako e-známky</li>
                                        <li>pro vozidla do 3,5t</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Chorvatská mýtná jednotka</li>
                                                <li>Rakouské úsekové mýto</li>
                                                <li>Slovenská e-známka</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );

            }
        case 'ch':
            switch (tolltype) {
                case 'etoll':
                    return (
                        <>
                            <h4>Švýcarská e-známka</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="ch.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>cestujte bez starostí, vyřešte dálniční poplatky v předstihu</li>
                                        <li>e-známky mají ve Švýcarsku stejnou platnost jako papírové kupony</li>
                                        <li>ušetřete za poštovné</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Italská mýtná jednotka</li>
                                                <li>Rakouská e-známka</li>
                                                <li>Rakouské úsekové mýto</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
                case 'vignette':
                    return (
                        <>
                            <h4>Švýcarský kupón - papírový</h4>
                            <Row noGutters>
                                <Col xs='12' sm='4' lg='3'><Image src="ch.png" fluid /></Col>
                                <Col xs='12' sm='8' lg='9'>
                                    <ul className='text-secondary'>
                                        <li>pro vozidla do 3,5t</li>
                                        <li>s roční platností do 31. ledna následujícího roku</li>
                                        <li>k nalepení na čelní sklo vozidla</li>
                                    </ul>
                                    <Row>
                                        <Col xs="6">
                                            K tomuto produktu zákazníci také často kupují:
                                        </Col>
                                        <Col xs="6">
                                            <ul className="text-secondary">
                                                <li>Italská mýtná jednotka</li>
                                                <li>Rakouský kupón - papírový</li>
                                                <li>Rakouské úsekové mýto</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    );
            }

        case 'sk':
            return (
                <>
                    <h4>Slovenská e-známka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="sk.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>cestujte bez starostí, vyřešte si mýtné pro Slovensko v předstihu</li>
                                <li>na cestě není nutné mít u sebe potvrzení o registraci</li>
                                <li>jednoduše a rychle</li>
                                <li>pro vozidla do 3,5t</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Maďarská e-známka</li>
                                        <li>Chorvatská mýtná jednotka</li>
                                        <li>Rakouský kupón - papírový</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'hu':
            return (
                <>
                    <h4>Maďarská e-známka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="hu.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>cestujte bez starostí, vyřešte si mýtné pro Maďarsko v předstihu</li>
                                <li>potvrzení o registraci Vám pošleme e-mailem</li>
                                <li>na cestě je nutné mít u sebe i potvrzení o registraci</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Chorvatská mýtná jednotka</li>
                                        <li>Slovenská e-známka</li>
                                        <li>Česká e-známka</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'slon':
            return (
                <>
                    <h4>Slovinská e-známka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="slon.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>cestujte bez starostí, vyřešte si e-známku pro Slovinsko v&nbsp;předstihu až 30 dní</li>
                                <li>potvrzení o registraci Vám pošleme e-mailem</li>
                                <li>na cestě nemusíte mít u sebe potvrzení o registraci</li>
                                <li>neznáte druh vozidla? Více zjistíte <a target="_blank" href="https://www.mtx.cz/wp-content/uploads/2022/03/Slovinsko-seznam-zmerenych-vozidel_CZ.pdf">ZDE</a></li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Chorvatská mýtná jednotka</li>
                                        <li>Slovenská e-známka</li>
                                        <li>Rakouská e-známka a tunely</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'slo':
            return (
                <>
                    <h4>Slovinský kupón - papírový</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="slo.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>cestujte bez starostí, vyřešte dálniční poplatky pro Slovinsko v předstihu</li>
                                <li>k nalepení na čelní sklo vozidla</li>
                                <li>zrychlí Vaši cestu do Chorvatska</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Rakouská e-známka</li>
                                        <li>Chorvatská mýtná jednotka</li>
                                        <li>Rakouské úsekové mýto</li>
                                        <li>Italská mýtná jednotka</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'hr':
            return (
                <>
                    <h4>Chorvatská mýtná jednotka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="hr.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>elektronická jednotka pro bezhotovostní úhradu mýta v zahraničí</li>
                                <li>cestujte bez starostí – vyhněte se kolonám při úhradě mýta</li>
                                <li>zvolte si výši předplaceného kreditu podle Vašich potřeb, nespotřebovaný kredit Vám vrátíme</li>
                                <li>poskytujeme slevu 21,74% z částky projetého mýta</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Slovenská e-známka</li>
                                        <li>Maďarská e-známka</li>
                                        <li>Rakouská e-známka</li>
                                        <li>Rakouské úsekové mýto</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'it':
            return (
                <>
                    <h4>Italská mýtná jednotka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="it.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>pro vozidla do 3,5t, bez omezení výšky </li>
                                <li>elektronická jednotka pro bezhotovostní úhradu mýta v zahraničí</li>
                                <li>cestujte bez starostí – vyhněte se kolonám při výběru mýta</li>
                                <li>nespotřebovaný kredit Vám vrátíme</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Rakouský kupón - papírový</li>
                                        <li>Rakouské úsekové mýto</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'fr':
            return (
                <>
                    <h4>Francouzská mýtná jednotka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="fr.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>elektronická jednotka pro bezhotovostní úhradu mýta v zahraničí</li>
                                <li>cestujte bez starostí – vyhněte se kolonám při výběru mýta</li>
                                <li>zvolte si výši předplaceného kreditu podle Vašich potřeb</li>
                                <li>nespotřebovaný kredit Vám vrátíme</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Švýcarský kupón - papírový</li>
                                        <li>Rakouský kupón – papírový</li>
                                        <li>Česká e-známka</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'no-dk-swe':
            return (
                <>
                    <h4>Skandinávská mýtná jednotka</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="no-dk-swe.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>pro vozidla do 3,5t, bez omezení výšky </li>
                                <li>platí pro Norsko, Švédsko a Dánsko</li>
                                <li>cestujte bez starostí – vyhněte se kolonám při výběru mýta</li>
                                <li>nespotřebovaný kredit Vám vrátíme</li>
                            </ul>
                            <Row>
                                <Col xs="6">
                                    K tomuto produktu zákazníci také často kupují:
                                </Col>
                                <Col xs="6">
                                    <ul className="text-secondary">
                                        <li>Česká e-známka</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        case 'gv':
            return (
                <>
                    <h4>Voucher na mýto</h4>
                    <Row noGutters>
                        <Col xs='12' sm='4' lg='3'><Image src="czv.png" fluid /></Col>
                        <Col xs='12' sm='8' lg='9'>
                            <ul className='text-secondary'>
                                <li>praktický a užitečný dárek pro firemní klienty i jednotlivce</li>
                                <li>získáte dárkový poukaz, který platí po dobu 10 měsíců</li>
                                <li>zakoupený voucher obdržíte v elektronické podobě, můžete jej přeposlat nebo vytisknout</li>
                                <li>možnost vložit osobní věnování</li>
                                <li>pro vozidla do 3,5t</li>
                                <li>v případě objednávky nad 100.000 Kč nás kontaktujte pro individuální nabídku</li>
                            </ul>
                        </Col>
                    </Row>
                </>
            );
        default:
            return <p>Popis není k dispozici.</p>;
    }
}
